<script setup lang="ts">
import { inject } from 'vue'
import type { Mission } from '@/models/Mission'

const mission: Mission = inject('mission') as Mission
</script>

<template>
  <v-sheet class="comment px-7 py-5" rounded="lg" v-if="mission.comment">
    <h3>Commentaire interne sur la mission</h3>
    <p v-html="mission.commentHtml"></p>
  </v-sheet>
</template>

<style scoped lang="scss">
.comment {
  p {
    font-size: 0.95rem;
  }
}
</style>
