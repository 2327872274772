let watchId: number

const options = {
  maximumAge: 0,
  timeout: 5000,
  enableHighAccuracy: false
}

export const shortCoords = (coords: { latitude: number; longitude: number }) => ({
  lat: coords.latitude,
  lng: coords.longitude
})

export const longCoords = (coords: { latitude: number; longitude: number }) => ({
  latitude: coords.latitude,
  longitude: coords.longitude
})

export const getCurrentPosition = (getShortCoords: boolean = true) => {
  if (!navigator.geolocation)
    return Promise.reject(new Error(`This browser doesn't support GeoLocation API.`))

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) =>
        resolve(getShortCoords ? shortCoords(position.coords) : longCoords(position.coords)),
      (error: GeolocationPositionError) => reject(error),
      options
    )
  })
}

export const watchPosition = (callback: Function) => {
  const success = (position: GeolocationPosition) => callback(shortCoords(position.coords))
  const error = (error: GeolocationPositionError) =>
    console.error(`Watch position error (${error.code}): ${error.message}`)

  watchId = navigator.geolocation.watchPosition(success, error, options)
}

export const clearWatchPosition = () => watchId && navigator.geolocation.clearWatch(watchId)
