<script setup lang="ts">
import { inject } from 'vue'
import type { Mission } from '@/models/Mission'

const mission: Mission = inject('mission') as Mission
</script>

<template>
  <v-sheet class="media px-7 py-5" rounded="lg">
    <h3>Prévision des médias à détruire</h3>
    <p class="my-2" v-for="(medium, index) in mission.forecastedMedia" :key="index">
      <v-icon icon="error" color="warning" v-if="medium.ultraConfidential"></v-icon>
      {{ medium.label }}: {{ medium.forecastQuantity.quantity }} {{ medium.forecastQuantity.unity }}
    </p>
  </v-sheet>
</template>

<style scoped lang="scss">
.media {
  p {
    font-size: 0.95rem;
  }
  .v-icon {
    font-size: 1.35rem;
    position: relative;
    top: -0.05rem;
  }
}
</style>
