import { ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import { Mission, MissionStatus, Period } from '@/models/Mission'
import { useSyncStore } from '@/stores/sync'

export const useMissionStore = defineStore(
  'missions',
  () => {
    const missions: Ref<Mission[]> = ref([])

    const getData = () => missions.value
    const setData = (data: Mission[]) => (missions.value = data)
    const getMissionsByPeriod = (period: Period) =>
      missions.value.filter((mission) => mission.inPeriod(period))
    const getMission = (id: number) => missions.value.find((mission) => mission.id === id)

    const finalizeMission = async (mission: Mission, newMissionDate?: string) => {
      mission.newMissionDate = newMissionDate
      mission.updateMediaOrder()
      await mission.setCurrentGeolocalization()

      const syncStore = useSyncStore()
      await syncStore.syncMission(mission)

      mission.status = MissionStatus.Done
      mission.truck?.addWeight(mission.media)
    }

    return {
      missions,
      getData,
      setData,
      getMissionsByPeriod,
      getMission,
      finalizeMission
    }
  },
  {
    persist: {
      afterRestore: (ctx) => {
        ctx.store.missions = ctx.store.missions.map((mission: Mission) => new Mission(mission))
      }
    }
  }
)
