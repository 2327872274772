<script setup lang="ts">
import { inject, nextTick } from 'vue'
import type { Mission } from '@/models/Mission'
import type { Medium } from '@/models/Medium'
import MediumRow from './Medium.vue'

const mission: Mission = inject('mission') as Mission

const getNextMediumOrder = () => {
  let highestOrder = 0
  mission.media.forEach((m) => {
    if (highestOrder < m.order) {
      highestOrder = m.order
    }
  })
  return highestOrder + 1
}
const addNewMedium = () => {
  const medium = {
    label: '',
    ultraConfidential: false,
    order: getNextMediumOrder(),
    destroyedQuantity: {}
  }
  mission.media.push(medium)
}
const removeMedium = async (order: number) => {
  const index = mission.media.findIndex((m: Medium) => m.order === order)
  mission.media.splice(index, 1)
}
</script>

<template>
  <section class="media">
    <MediumRow
      :medium="medium"
      v-for="medium in mission.orderedMedia"
      :key="medium.order"
      :editable="!medium.forecastQuantity"
      @remove="removeMedium(medium.order)"
    ></MediumRow>
    <v-btn size="large" variant="outlined" rounded="xl" @click="addNewMedium"
      >Ajouter un média à détruire</v-btn
    >
  </section>
</template>

<style scoped lang="scss">
.media {
  * {
    margin: var(--page-section-spacing) 0;
  }
  .v-btn {
    display: block;
  }
}
</style>
