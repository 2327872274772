<script setup lang="ts">
import { ref, computed, provide, type Ref, type ComputedRef } from 'vue'
import { useRouter } from 'vue-router'
import { useLocalStorage, type RemovableRef } from '@vueuse/core'
import { useTruckStore } from '@/stores/trucks'
import { initEmptyTruckState, type EmptyTruck } from '@/forms/EmptyTruck'
import Header from '@/components/Header.vue'
import TruckMedia from './components/TruckMedia.vue'
import RecyclingCenter from './components/RecyclingCenter.vue'
import Quantity from './components/Quantity.vue'
import RecyclingDocument from './components/RecyclingDocument.vue'

const truckStore = useTruckStore()
const router = useRouter()

const formState = useLocalStorage<EmptyTruck>('empty_truck_form_state', initEmptyTruckState())
const saving = ref(false)
const showSnackbar = ref(false)
const error = ref('')

const showFullForm = computed(() => !!formState.value.truckParkCode && !!formState.value.mediaType)

const needsQuantityAndDocument = computed(() => !!formState.value.recyclingCenter?.recylingCenter)

const readyForSave = computed(() => {
  if (!showFullForm.value || !formState.value.recyclingCenter) return false

  return needsQuantityAndDocument.value
    ? !!formState.value.quantity && !!formState.value.recyclingDocument
    : true
})

const saveEmptying = async () => {
  saving.value = true
  try {
    await truckStore.saveEmptying(formState.value)
    showSnackbar.value = true

    setTimeout(() => {
      router.push({ name: 'planning' })
      formState.value = initEmptyTruckState()
    }, 3000)
  } catch (e) {
    saving.value = false
    error.value = `L'enregistrement a échoué, veuillez vérifier les champs du formulaire.`
    console.error(e.message)

    setTimeout(() => (error.value = ''), 4000)
  }
}

provide<RemovableRef<EmptyTruck>>('formState', formState)
provide<ComputedRef<boolean>>('showFullForm', showFullForm)
provide<ComputedRef<boolean>>('needsQuantityAndDocument', needsQuantityAndDocument)
provide<Ref<boolean>>('saving', saving)
</script>

<template>
  <div class="page empty-truck">
    <div class="form sub-page">
      <Header :route="{ name: 'planning' }">Retour au planning</Header>
      <h1>Vidage d'un camion</h1>

      <TruckMedia />
      <RecyclingCenter />
      <Quantity />
      <RecyclingDocument />

      <p class="notice font-italic" v-if="!showFullForm">
        Veuillez sélectionner un camion et un média pour renseigner les informations
        complémentaires.
      </p>
      <p><span class="text-primary">*</span> : informations obligatoires</p>

      <div class="text-center pt-4 pb-8">
        <v-btn
          size="large"
          rounded="xl"
          :disabled="!readyForSave || saving"
          :loading="saving"
          @click="saveEmptying"
        >
          Enregistrer le vidage
        </v-btn>
        <v-fade-transition>
          <p class="text-error mt-3" v-if="error">
            {{ error }}
          </p>
        </v-fade-transition>
      </div>
    </div>
    <v-snackbar v-model="showSnackbar" :vertical="true" color="success" :timeout="2500">
      Le vidage du camion est enregistré.
      <template #actions>
        <v-btn variant="text" color="white" @click="showSnackbar = false"> Fermer </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped lang="scss">
.empty-truck {
  h1 {
    font-size: 1.2rem;
  }
  .notice {
    font-size: 0.9rem;
  }
}
</style>
