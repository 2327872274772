<script setup lang="ts">
import { ref, watch, inject, onMounted, onBeforeUnmount, nextTick, type Ref } from 'vue'
import type { Mission } from '@/models/Mission'
import * as geolocation from '@/services/geolocation'

const missions: Ref<Mission[]> = inject('plannedMissions', ref([]))
watch(missions, () => {
  if (!map) return

  missionMarkers.forEach((m) => m.setMap(null))
  missionMarkers = []
  missions.value.forEach((m) => addMissionMarker(m))
  adaptZoom()
})

let map = null
let currentPositionMarker = null
let missionMarkers = []
const DEFAULT_POSITION = { lat: 48.8567, lng: 2.3522 } // Paris
const mapContainer = ref(null)

const addCurrentPositionMarker = (position) => {
  const marker = new google.maps.marker.AdvancedMarkerElement({
    map,
    position,
    title: 'Position actuelle'
  })

  const infoWindow = new google.maps.InfoWindow({
    content: 'Position actuelle'
  })
  marker.addListener('click', () => {
    infoWindow.open(map, marker)
  })

  currentPositionMarker = marker
}

const addMissionMarker = (mission: Mission) => {
  const position = geolocation.shortCoords(mission.address.geolocalization)

  const pinViewBackground = new google.maps.marker.PinElement({
    background: '#0068cb',
    glyphColor: '#1f2c3d',
    borderColor: '#1f2c3d'
  })
  const marker = new google.maps.marker.AdvancedMarkerElement({
    map,
    position,
    title: mission.companyName,
    content: pinViewBackground.element
  })

  const infoWindow = new google.maps.InfoWindow({
    content: mission.companyName
  })
  marker.addListener('click', () => infoWindow.open(map, marker))

  missionMarkers.push(marker)
}

const adaptZoom = async () => {
  if (currentPositionMarker && missionMarkers.length === 0) {
    map.setCenter(currentPositionMarker.position)
    map.setZoom(12)
  } else if (!currentPositionMarker && missionMarkers.length === 1) {
    map.setCenter(missionMarkers[0].position)
    map.setZoom(12)
  } else if (!currentPositionMarker && missionMarkers.length === 0) {
    map.setCenter(DEFAULT_POSITION)
    map.setZoom(12)
  } else {
    const bounds = new google.maps.LatLngBounds(0, 0)

    missionMarkers.forEach((marker) => bounds.extend(marker.position))
    currentPositionMarker && bounds.extend(currentPositionMarker.position)

    map.fitBounds(bounds)
  }
}

const showMap = async () => {
  if (!online.value) return

  await google.maps.importLibrary('maps')
  await google.maps.importLibrary('marker')

  // Map
  map = new google.maps.Map(mapContainer.value, {
    zoom: 12,
    center: DEFAULT_POSITION,
    mapId: 'DESTRUDATA_APP_MAP',
    disableDefaultUI: true,
    zoomControl: true,
    gestureHandling: 'greedy'
  })

  // Mission markers
  missionMarkers = []
  missions.value.forEach((m) => addMissionMarker(m))
  adaptZoom()

  // Current position
  try {
    currentPositionMarker = null
    const pos = await geolocation.getCurrentPosition()
    addCurrentPositionMarker(pos)
    geolocation.watchPosition((pos) => (currentPositionMarker.position = pos))
  } catch (e) {
    console.error(e.message)
  }
  adaptZoom()
}

const online = ref(navigator.onLine)
const onlineHandler = async () => {
  online.value = true
  showMap()
}
const offlineHandler = () => {
  online.value = false
  geolocation.clearWatchPosition()
}
window.addEventListener('online', onlineHandler)
window.addEventListener('offline', offlineHandler)

onMounted(showMap)
onBeforeUnmount(() => {
  geolocation.clearWatchPosition()
  window.removeEventListener('online', onlineHandler)
  window.removeEventListener('offline', offlineHandler)
})
</script>

<template>
  <v-sheet class="dark-border" color="transparent" rounded="lg" border>
    <div class="map rounded-lg" ref="mapContainer" v-show="online"></div>
    <p class="error pa-4" v-show="!online">
      <v-icon icon="wifi_off"></v-icon> La carte n'est pas accessible hors connexion.
    </p>
  </v-sheet>
</template>

<style scoped lang="scss">
.map {
  width: 100%;
  height: 40vh;
}
.error {
  font-size: 0.95rem;
  font-style: italic;
  text-align: center;
}
</style>
