import type { Address } from './Address'

export enum RecyclingCenterType {
  Recycling = 'recycling',
  Incinerator = 'incinerator'
}

export interface RecyclingCenter {
  readonly id: number
  readonly companyName: string
  readonly recylingCenter: boolean
  readonly type?: RecyclingCenterType
  readonly address: Address
}
