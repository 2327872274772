<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, minValue, time } from '@/plugins/vuelidate'
import { MediumUnity, type Medium } from '@/models/Medium'
import { useMediaTypeStore } from '@/stores/media_types'

interface IProps {
  medium: Medium
  editable?: boolean
}
const props = withDefaults(defineProps<IProps>(), {
  editable: false
})

const medium = ref(props.medium)
if (medium.value.destroyedQuantity.quantity === 0) {
  medium.value.destroyedQuantity.quantity = undefined
}
if (!medium.value.destructionTime) {
  medium.value.destructionTime = '00:00'
}

const containerRequired = computed(
  () =>
    !!(
      ['Papier', 'Papier UC'].includes(medium.value.label) &&
      medium.value.forecastQuantity &&
      medium.value.forecastQuantity?.unity !== 'kg'
    )
)

const rules = {
  label: { required },
  destroyedQuantity: {
    quantity: {
      required,
      minValue: minValue(0)
    },
    unity: { required },
    container: {
      required: requiredIf(containerRequired),
      minValue: minValue(0)
    }
  },
  destructionTime: {
    required,
    time
  }
}
const v$ = useVuelidate(rules, medium)

const mediaTypeStore = useMediaTypeStore()
const mediaTypes = mediaTypeStore.mediaTypes

const unityItems = Object.values(MediumUnity)
const unityDisabled = computed(
  () => !props.editable || ['Papier', 'Papier UC'].includes(medium.value.label)
)

watch(
  () => medium.value.label,
  (newLabel) => {
    if (['Papier', 'Papier UC'].includes(newLabel)) {
      medium.value.destroyedQuantity.unity = MediumUnity.Kg
    }
  }
)
watch(
  () => medium.value.destructionTime,
  (newTime, oldTime) => {
    if (newTime?.length === 2 && oldTime?.length === 1 && !newTime?.includes(':')) {
      medium.value.destructionTime += ':'
    }
  }
)
</script>

<template>
  <v-sheet class="medium px-7 pt-5 pb-7" rounded="lg">
    <template v-if="medium.forecastQuantity">
      <p>
        <strong>Média :</strong>
        <v-icon icon="error" class="text-warning" v-if="medium.ultraConfidential"></v-icon>
        {{ medium.label }}
      </p>
      <p v-if="medium.forecastQuantity">
        <strong>Prévision :</strong> {{ medium.forecastQuantity.quantity }}
        {{ medium.forecastQuantity.unity }}
      </p>
    </template>
    <div class="field" v-else>
      <v-label :class="{ required: true, 'text-error': v$.label.$errors.length > 0 }">
        Média
      </v-label>
      <div class="row">
        <v-select
          v-model="medium.label"
          :items="mediaTypes"
          :error-messages="v$.label.$errors.map((e) => e.$message)"
          color="primary"
          variant="outlined"
          density="compact"
          required
        ></v-select>
        <v-btn class="font-weight-bold" variant="text" @click="$emit('remove')">
          <v-icon icon="delete" color="primary"></v-icon>
          Supprimer le média
        </v-btn>
      </div>
    </div>
    <fieldset>
      <div class="field">
        <v-label
          :class="{
            required: true,
            'text-error': v$.destroyedQuantity.quantity.$errors.length > 0
          }"
        >
          Quantité détruite
        </v-label>
        <v-text-field
          v-model.number="medium.destroyedQuantity.quantity"
          :error-messages="v$.destroyedQuantity.quantity.$errors.map((e) => e.$message)"
          type="number"
          color="primary"
          required
        ></v-text-field>
      </div>
      <div class="field">
        <v-label
          :class="{
            required: true,
            disabled: unityDisabled,
            'text-error': v$.destroyedQuantity.unity.$errors.length > 0
          }"
        >
          Unité
        </v-label>
        <v-select
          v-model="medium.destroyedQuantity.unity"
          :items="unityItems"
          :disabled="unityDisabled"
          :error-messages="v$.destroyedQuantity.unity.$errors.map((e) => e.$message)"
          color="primary"
          variant="outlined"
          density="compact"
          required
        ></v-select>
      </div>
      <div class="field">
        <v-label :class="{ required: true, 'text-error': v$.destructionTime.$errors.length > 0 }">
          Durée
        </v-label>
        <v-text-field
          v-model="medium.destructionTime"
          :error-messages="v$.destructionTime.$errors.map((e) => e.$message)"
          placeholder="__:__"
          type="time"
          color="primary"
          required
        >
          <!--<template #append-inner>
            <v-icon icon="schedule" color="primary" />
          </template>-->
        </v-text-field>
      </div>
      <div class="field" v-if="containerRequired">
        <v-label
          :class="{
            required: true,
            'text-error': v$.destroyedQuantity.container.$errors.length > 0
          }"
        >
          Nombre de {{ medium.forecastQuantity?.unity }}
        </v-label>
        <v-text-field
          v-model.number="medium.destroyedQuantity.container"
          :error-messages="v$.destroyedQuantity.container.$errors.map((e) => e.$message)"
          type="number"
          color="primary"
          required
        >
        </v-text-field>
      </div>
    </fieldset>
  </v-sheet>
</template>

<style scoped lang="scss">
.medium {
  > * {
    margin: 0.75rem 0;
  }
  p {
    .v-icon {
      font-size: 1.3rem;
      position: relative;
      top: -0.1rem;
      margin: 0 0.1rem;
    }
  }
  .field {
    margin-bottom: -1rem;
    .row {
      display: flex;
      .v-btn {
        margin-top: 0.25rem;
        margin-left: 0.5rem;
        .v-icon {
          font-size: 1.35rem;
          position: relative;
          top: 0.1rem;
          margin-right: 0.2rem;
        }
      }
    }
  }
  fieldset {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    border: none;
    .field {
      margin-bottom: -1.75rem;
      .v-label {
        max-width: 100%;
        position: absolute;
      }
      .v-input {
        margin-top: 1.75rem;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .medium {
    fieldset {
      grid-template-columns: 1fr;
      .field {
        .v-label {
          position: initial;
          white-space: pre-wrap;
        }
        .v-input {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
