<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  mode: {
    type: String,
    default: 'out-in'
  },
  duration: {
    type: [String, Number], // 'default' | 'fast' | nbMS
    default: 'default'
  },
  appear: {
    type: Boolean,
    default: false
  }
})

const duration = computed(() => {
  if (typeof props.duration === 'string') {
    return props.duration === 'fast' ? '100ms' : '250ms'
  }
  return `${props.duration}ms`
})
</script>

<template>
  <Transition name="fade" :mode="props.mode" :appear="props.appear">
    <slot></slot>
  </Transition>
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity v-bind(duration) ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
