import { useAuthStore } from '@/stores/auth'
import { reverseContactFormat, reverseMediumFormat } from './format'
import { MissionStatus, type Mission } from '@/models/Mission'
import type { EmptyTruck } from '@/forms/EmptyTruck'

export const syncMission = async (mission: Mission) => {
  if (!navigator.onLine) {
    console.log(`No sync of mission ${mission.id} because device is offline`)
    return false
  }

  const authStore = useAuthStore()
  if (!authStore.isAuthenticated) {
    authStore.logout()
    throw new Error(`No sync of mission ${mission.id} because user is not authenticated`)
  }

  const body = {
    media: mission.media.map(reverseMediumFormat),
    waste_track_number: mission.wasteTrackNumber,
    witness_contact: mission.witnessContact && reverseContactFormat(mission.witnessContact),
    witness_contact_name: mission.witnessContactName,
    geolocalization: mission.geolocalization,
    signature_date_time: mission.signatureDateTime,
    signature: mission.signature,
    new_mission_date: mission.newMissionDate,
    status: MissionStatus.Done
  }

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authStore.authToken
    },
    body: JSON.stringify(body)
  }

  const apiUrl =
    import.meta.env.VITE_USE_MOCKS !== 'true'
      ? `${import.meta.env.VITE_API_URL}/missions/${mission.id}`
      : new URL(`/src/mocks/finalize_mission.json`, import.meta.url).href

  const response = await fetch(apiUrl, options)
  const data = await response.json()

  if (response.status === 400) {
    throw new Error(`Failed to sync mission ${mission.id}, bad request: ${JSON.stringify(data)}`)
  }
  if (response.status === 401) {
    authStore.logout()
    throw new Error(`Failed to sync mission ${mission.id}, unauthorized: ${JSON.stringify(data)}`)
  }
  if (!response.ok || !data.success) {
    console.error(
      `Failed to sync mission ${mission.id}, no bad request, will retry: ${JSON.stringify(data)}`
    )
    return false
  }
  return true
}

export const syncTruck = async (emptyTruck: EmptyTruck) => {
  if (!navigator.onLine) {
    console.log(`No sync of truck ${emptyTruck.truckParkCode} because device is offline`)
    return false
  }

  const authStore = useAuthStore()
  if (!authStore.isAuthenticated) {
    authStore.logout()
    throw new Error(
      `NO sync of truck ${emptyTruck.truckParkCode} because user is not authenticated`
    )
  }

  const body = {
    medium_name: emptyTruck.mediaType,
    weight: emptyTruck.quantity,
    recycling_center_id: emptyTruck.recyclingCenter?.id,
    recycling_document: emptyTruck.recyclingDocument,
    treatment_date: emptyTruck.treatmentDate
  }

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authStore.authToken
    },
    body: JSON.stringify(body)
  }

  const apiUrl =
    import.meta.env.VITE_USE_MOCKS !== 'true'
      ? `${import.meta.env.VITE_API_URL}/trucks/${emptyTruck.truckParkCode}/empty`
      : new URL(`/src/mocks/empty_truck.json`, import.meta.url).href

  const response = await fetch(apiUrl, options)
  const data = await response.json()

  if (response.status === 400) {
    throw new Error(
      `Failed to sync truck ${emptyTruck.truckParkCode}, bad request: ${JSON.stringify(data)}`
    )
  }
  if (response.status === 401) {
    authStore.logout()
    throw new Error(
      `Failed to sync truck ${emptyTruck.truckParkCode}, unauthorized: ${JSON.stringify(data)}`
    )
  }
  if (!response.ok || !data.success) {
    console.error(
      `Failed to sync truck ${
        emptyTruck.truckParkCode
      }, no bad request, will retry: ${JSON.stringify(data)}`
    )
    return false
  }
  return true
}
