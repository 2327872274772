<script setup lang="ts">
import { inject } from 'vue'
import type { Mission } from '@/models/Mission'
import Contact from '@/components/Contact.vue'

const mission: Mission = inject('mission') as Mission
</script>

<template>
  <v-sheet class="info dark-border px-5 py-4" color="transparent" rounded="lg" border>
    <h3>{{ mission.companyName }}</h3>
    <address>{{ mission.fullAddress }}</address>
    <Contact
      :contact="contact"
      v-for="contact in mission.contacts"
      :key="`${contact.firstName} ${contact.lastName}`"
    />
  </v-sheet>
</template>

<style scoped lang="scss">
.info > * {
  margin: 0.25rem 0;
}
</style>
