import { ref, computed, type Ref } from 'vue'
import { defineStore } from 'pinia'
import { getCurrentPosition, shortCoords } from '@/services/geolocation'
import type { RecyclingCenter } from '@/models/RecyclingCenter'

export const useRecyclingCenterStore = defineStore(
  'recycling_centers',
  () => {
    const recyclingCenters: Ref<RecyclingCenter[]> = ref([])

    const getData = () => recyclingCenters.value
    const setData = (data: RecyclingCenter[]) => (recyclingCenters.value = data)

    const getRecyclingCenter = (id: number) =>
      recyclingCenters.value.find((center) => center.id === id)

    const getCentersByProximity = async () => {
      const centers = recyclingCenters.value

      try {
        const currentPosition = await getCurrentPosition(true)
        await google.maps.importLibrary('geometry')

        const centersWithDistance = centers.map((center) => {
          const centerPosition = shortCoords(center.address.geolocalization)
          const distance = google.maps.geometry.spherical.computeDistanceBetween(
            currentPosition,
            centerPosition
          )
          return {
            center,
            distance
          }
        })

        centersWithDistance.sort((a, b) => a.distance - b.distance)

        return centersWithDistance.map((c) => c.center)
      } catch (e) {
        console.error(`Can't sort recycling centers by proximity: ${e.message}`)
        return centers
      }
    }

    return {
      recyclingCenters,
      getData,
      setData,
      getRecyclingCenter,
      getCentersByProximity
    }
  },
  {
    persist: true
  }
)
