import type { Truck } from '@/models/Truck'
import type { RecyclingCenter } from '@/models/RecyclingCenter'

export enum EmptyableMediaType {
  Paper = 'Papier',
  HardDrive = 'Disque dur',
  DIB = 'DIB'
}

export interface EmptyTruck {
  truckParkCode: string | null
  mediaType: EmptyableMediaType | null
  recyclingCenter: RecyclingCenter | null
  quantity: number | null
  recyclingDocument: string | null
  treatmentDate: string | null
}

export const initEmptyTruckState = (): EmptyTruck => ({
  truckParkCode: null,
  mediaType: null,
  recyclingCenter: null,
  quantity: null,
  recyclingDocument: null,
  treatmentDate: null
})
