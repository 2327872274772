<script setup lang="ts">
import { inject } from 'vue'
import type { RemovableRef } from '@vueuse/core'
import { EmptyableMediaType, type EmptyTruck } from '@/forms/EmptyTruck'
import { useTruckStore } from '@/stores/trucks'

const truckStore = useTruckStore()
const trucks = truckStore.trucks.map((truck) => ({
  title: truck.parkCode,
  value: truck.parkCode
}))

const mediaTypes = Object.values(EmptyableMediaType)

const formState: RemovableRef<EmptyTruck> = inject('formState') as RemovableRef<EmptyTruck>
</script>

<template>
  <v-sheet class="truck-media px-3 pt-2" rounded="lg">
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" class="pb-1">
          <v-label class="required">Camion à vider</v-label>
          <v-select
            :items="trucks"
            v-model="formState.truckParkCode"
            :value-comparator="(t1, t2) => t1 === t2"
            placeholder="Sélectionner un camion"
            color="primary"
            variant="outlined"
            density="compact"
            required
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-1">
          <v-label class="required">Type de média à vider</v-label>
          <v-select
            :items="mediaTypes"
            v-model="formState.mediaType"
            placeholder="Sélectionner un média"
            color="primary"
            variant="outlined"
            density="compact"
            required
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
