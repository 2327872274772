export enum MediumUnity {
  Kg = 'kg',
  Unity = 'Unité'
}

export interface MediumQuantity {
  unity: string
  quantity?: number
  container?: number
}

export interface Medium {
  label: string
  ultraConfidential: boolean
  order: number
  forecastQuantity?: MediumQuantity
  destroyedQuantity: MediumQuantity
  destructionTime?: string
}
