import type { Address, Geolocalization } from '@/models/Address'
import type { Contact } from '@/models/Contact'
import type { Driver } from '@/models/Driver'
import type { Medium, MediumQuantity } from '@/models/Medium'
import type { RecyclingCenter } from '@/models/RecyclingCenter'
import { Truck } from '@/models/Truck'
import { Mission } from '@/models/Mission'
import { DataType } from './data'

const formatGeolocalization = (data: any): Geolocalization => ({
  latitude: parseFloat(data['latitude']),
  longitude: parseFloat(data['longitude'])
})

const formatAddress = (data: any): Address => ({
  street: data['street'],
  zipCode: data['zip_code'],
  city: data['city'],
  geolocalization: formatGeolocalization(data['geolocalization'])
})

const formatContact = (data: any): Contact => ({
  firstName: data['first_name'],
  lastName: data['last_name'],
  phone1: data['phone_1'],
  phone2: data['phone_2']
})

const formatDriver = (data: any): Driver => ({
  firstName: data['first_name'],
  lastName: data['last_name']
})

const formatQuantity = (data: any): MediumQuantity => ({
  unity: data['unity'],
  quantity: data['quantity'] && parseInt(data['quantity']),
  container: data['container'] && parseInt(data['container'])
})

const formatMedium = (data: any): Medium => ({
  label: data['label'],
  ultraConfidential: data['ultraconfidential'],
  order: data['order'],
  forecastQuantity: formatQuantity(data['forecast_quantity']),
  destroyedQuantity: formatQuantity(data['destroyed_quantity']),
  destructionTime: data['destruction_time']
})

const formatRecyclingCenter = (data: any): RecyclingCenter => ({
  id: data['id'],
  companyName: data['company_name'],
  recylingCenter: data['recycling_center'],
  type: data['type'],
  address: formatAddress(data['address'])
})

const formatTruck = (data: any): Truck =>
  new Truck({
    parkCode: data['park_code'],
    wasteWeight: parseInt(data['waste_weight']),
    hardDriveNumber: parseInt(data['hard_drive_number']),
    hardDriveWeight: parseInt(data['hard_drive_weight']),
    dibNumber: parseInt(data['dib_number']),
    dibWeight: parseInt(data['dib_weight'])
  })

const formatMission = (data: any): Mission =>
  new Mission({
    id: data['id'],
    companyName: data['company_name'],
    plannedStartDateTime: data['planned_start_date_time'],
    plannedEndDateTime: data['planned_end_date_time'],
    comment: data['comment'],
    openingHours: data['opening_hours'],
    accessTerms: data['access_terms'],
    address: formatAddress(data['address']),
    contacts: data['contacts'].map(formatContact),
    truckParkCode: data['truck']['park_code'],
    documents: data['documents'],
    media: data['media'].map((data: object) => formatMedium(data)),
    witnessContact: data['witness_contact'] && formatContact(data['witness_contact']),
    witnessContactName: data['witness_contact_name'],
    wasteTrackNumber: data['waste_track_number'],
    geolocalization: data['geolocalization'] && formatGeolocalization(data['geolocalization']),
    signature: data['signature'],
    signatureDateTime: data['signature_date_time'],
    status: data['status'],
    otherDrivers: (data['other_drivers'] && data['other_drivers'].map(formatDriver)) || []
  })

const dataTypeMapping = {
  [DataType.MediaType]: (data: string): string => data,
  [DataType.RecyclingCenter]: formatRecyclingCenter,
  [DataType.Truck]: formatTruck,
  [DataType.Mission]: formatMission
}

export const format = (type: DataType, data: Array<Object>) => {
  return data.map(dataTypeMapping[type])
}

export const reverseContactFormat = (contact: Contact) => ({
  first_name: contact.firstName,
  last_name: contact.lastName,
  phone1: contact.phone1,
  phone2: contact.phone2
})

export const reverseMediumFormat = (medium: Medium) => ({
  label: medium.label,
  ultraconfidential: medium.ultraConfidential,
  order: medium.order,
  forecast_quantity: medium.forecastQuantity,
  destroyed_quantity: medium.destroyedQuantity,
  destruction_time: medium.destructionTime
})
