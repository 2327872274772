<script setup lang="ts">
import { ref, computed, inject, onMounted, onBeforeUnmount } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@/plugins/vuelidate'
import type { Mission } from '@/models/Mission'
import { getLocaleISOString } from '@/services/utils'
import SignaturePad from 'signature_pad'

const mission: Mission = inject('mission') as Mission

const rules = {
  witnessContact: {
    required: requiredIf(() => !mission.witnessContactName)
  },
  witnessContactName: {
    required: requiredIf(() => !mission.witnessContact)
  },
  signature: { required }
}

const v$ = useVuelidate(rules, mission)

const otherContact = ref(!!mission.witnessContactName)
if (mission.contacts.length === 1 && !mission.witnessContact && !mission.witnessContactName) {
  mission.witnessContact = mission.contacts[0]
}

const contactsItems = computed(() =>
  mission.contacts.map((contact) => ({
    title: `${contact.firstName} ${contact.lastName}`,
    value: contact
  }))
)

const showSelectContactField = () => {
  mission.witnessContactName = undefined
  otherContact.value = false
}
const showTextContactField = () => {
  mission.witnessContact = undefined
  otherContact.value = true
}

const signature = ref(null)
let signaturePad: SignaturePad

const resizeCanvas = () => {
  const ratio = Math.max(window.devicePixelRatio || 1, 1)
  signature.value.width = signature.value.offsetWidth * ratio
  signature.value.height = signature.value.offsetHeight * ratio
  signature.value.getContext('2d').scale(ratio, ratio)
  signaturePad.clear()
}

const clearCanvas = () => {
  signaturePad.clear()
  mission.signature = undefined
  mission.signatureDateTime = undefined
}

onMounted(() => {
  signaturePad = new SignaturePad(signature.value)

  window.addEventListener('resize', resizeCanvas)
  resizeCanvas()

  if (mission.signature) {
    signaturePad.fromDataURL(mission.signature)
  }
  signaturePad.addEventListener('endStroke', () => {
    mission.signature = signaturePad.toDataURL()
    mission.signatureDateTime = getLocaleISOString(new Date())
  })
})
onBeforeUnmount(() => window.removeEventListener('resize', resizeCanvas))
</script>

<template>
  <v-sheet class="save px-7 pt-5 pb-7" rounded="lg">
    <fieldset>
      <div class="field">
        <v-label>Nº du bon Track Dechet</v-label>
        <v-text-field
          v-model="mission.wasteTrackNumber"
          color="primary"
          placeholder="ex: BSD-20230716-GDVP1EC00"
        ></v-text-field>
      </div>
      <div class="field witness" v-if="!otherContact">
        <v-label :class="{ required: true, 'text-error': v$.witnessContact.$errors.length > 0 }">
          Témoin
        </v-label>
        <div class="row">
          <v-select
            :items="contactsItems"
            v-model="mission.witnessContact"
            :value-comparator="(c1, c2) => JSON.stringify(c1) === JSON.stringify(c2)"
            :error-messages="v$.witnessContact.$errors.map((e) => e.$message)"
            color="primary"
            variant="outlined"
            density="compact"
            required
          ></v-select>
          <v-btn class="font-weight-bold" variant="text" @click="showTextContactField">
            <v-icon icon="edit" color="primary"></v-icon>
            Contact autre
          </v-btn>
        </div>
      </div>
      <div class="field witness" v-else>
        <v-label :class="{ required: true, 'text-error': v$.witnessContactName.$errors.length > 0 }"
          >Témoin</v-label
        >
        <div class="row">
          <v-text-field
            v-model="mission.witnessContactName"
            :error-messages="v$.witnessContactName.$errors.map((e) => e.$message)"
            color="primary"
            placeholder="Nom du témoin"
          ></v-text-field>
          <v-btn class="text-decoration-underline" variant="text" @click="showSelectContactField"
            >Annuler</v-btn
          >
        </div>
      </div>
      <div class="field signature">
        <div class="row">
          <v-label :class="{ required: true, 'text-error': v$.signature.$errors.length > 0 }">
            Signature
          </v-label>
          <v-btn icon="clear" variant="text" density="compact" @click="clearCanvas"></v-btn>
        </div>
        <canvas
          :class="{ rounded: true, error: v$.signature.$errors.length > 0 }"
          ref="signature"
        ></canvas>
        <v-messages
          class="font-weight-bold ml-3 mt-2"
          :active="v$.signature.$errors.length > 0"
          :messages="v$.signature.$errors.map((e) => e.$message)"
          color="error"
        >
        </v-messages>
      </div>
    </fieldset>
  </v-sheet>
</template>

<style scoped lang="scss">
.save {
  > * {
    margin: 0.75rem 0;
  }
  fieldset {
    border: none;
    .field {
      .v-label {
        margin-bottom: 0.25rem;
      }
      .row {
        display: flex;
        justify-content: space-between;
      }
      &.witness {
        .v-btn {
          margin-top: 0.25rem;
          margin-left: 0.5rem;
          .v-icon {
            font-size: 1.35rem;
            position: relative;
            top: 0.1rem;
            margin-right: 0.2rem;
          }
        }
      }
      &.signature {
        canvas {
          display: block;
          width: 100%;
          height: 20vh;
          border: 1px solid #aaa;
          &.error {
            border-color: rgb(var(--v-theme-error));
          }
        }
      }
    }
  }
}
</style>
