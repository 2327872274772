import { computed, ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import router from '@/plugins/router'

export const useAuthStore = defineStore(
  'auth',
  () => {
    const authToken: Ref<string | null> = ref(null)
    const isAuthenticated = computed(() => authToken.value != null)

    const setAuthToken = (token: string | null) => {
      authToken.value = token
    }

    const logout = () => {
      setAuthToken(null)
      router.push({ name: 'login' })
    }

    return { authToken, isAuthenticated, setAuthToken, logout }
  },
  {
    persist: true
  }
)
