<script setup lang="ts">
const props = defineProps({
  contact: {
    type: Object,
    required: true
  }
})
const contact = props.contact
</script>

<template>
  <p>
    <v-icon icon="person"></v-icon> {{ contact.firstName }} {{ contact.lastName }}
    <template v-if="contact.phone1"> - {{ contact.phone1 }}</template>
    <template v-if="contact.phone2"> - {{ contact.phone2 }}</template>
  </p>
</template>

<style lang="scss" scoped>
.v-icon {
  font-size: 1.35rem;
  position: relative;
  top: -0.05rem;
}
</style>
