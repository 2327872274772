<script setup lang="ts">
import { ref, computed, inject, type Ref } from 'vue'
import { Truck, DEFAULT_TRUCK_CAPACITY } from '@/models/Truck'
import type { Mission } from '@/models/Mission'
import { useSyncStore } from '@/stores/sync'
import { useTruckStore } from '@/stores/trucks'

const plannedMissions: Ref<Mission[]> = inject('plannedMissions', ref([]))
const doneMissions: Ref<Mission[]> = inject('doneMissions', ref([]))

const syncStore = useSyncStore()
const truckStore = useTruckStore()
const truck = computed(
  () =>
    plannedMissions.value[0]?.truck ||
    (doneMissions.value.length > 0 ? doneMissions.value.slice(-1)[0].truck : null) ||
    truckStore.trucks[0]
)

const filling = computed(() => truck.value?.fillingPercentage || 0)
const progressColor = computed(() => {
  if (filling.value < 60) return 'success'
  else if (filling.value < 80) return 'warning'
  else return 'error'
})
</script>

<template>
  <section :class="{ truck: true, sync: syncStore.emptyTrucksCount > 0 }" v-if="truck">
    <v-sheet class="filling pa-5" rounded="lg">
      <h3>
        <v-icon icon="local_shipping" class="mr-2"></v-icon> Remplissage du camion
        {{ truck.parkCode }}
      </h3>
      <v-progress-linear
        class="my-2"
        :model-value="filling"
        :color="progressColor"
        bg-color="#e3e4e6"
        bg-opacity="1"
        :height="22"
        rounded="xl"
        :rounded-bar="true"
      ></v-progress-linear>
      <div class="info">
        <div
          :class="{
            percentage: true,
            [`text-${progressColor}`]: true
          }"
        >
          {{ filling }}%
        </div>
        <div class="weight">
          {{ Truck.formatWeight(truck.wasteWeight) }} tonnes /
          {{ Truck.formatWeight(DEFAULT_TRUCK_CAPACITY) }} tonnes
        </div>
      </div>
    </v-sheet>
    <v-btn class="empty" size="large" rounded="lg" @click="$router.push({ name: 'empty-truck' })">
      Vider un camion
    </v-btn>
    <p class="sync" v-if="syncStore.emptyTrucksCount > 0">
      <v-icon icon="sync"></v-icon> {{ syncStore.emptyTrucksCount }} vidages de camion en attente de
      synchronisation
    </p>
  </section>
</template>

<style scoped lang="scss">
.truck {
  --button-size: 14.5vh;
  display: grid;
  grid-template: 'filling empty';
  grid-template-columns: 1fr var(--button-size);
  gap: 1rem;
  &.sync {
    grid-template:
      'filling empty'
      'sync sync';
    grid-template-columns: 1fr var(--button-size);
  }
  .filling {
    grid-area: filling;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .info {
      display: flex;
      justify-content: space-between;
      .percentage {
        font-weight: 700;
      }
    }
  }
  .empty {
    grid-area: empty;
    height: var(--button-size);
    &:deep(.v-btn__content) {
      padding: 0.5rem;
      font-weight: 700;
      white-space: pre-wrap;
    }
  }
  .sync {
    grid-area: sync;
    font-size: 0.8rem;
    font-style: italic;
    .v-icon {
      font-size: 0.85rem;
      position: relative;
      top: -0.1em;
      margin-right: 0.25rem;
    }
  }
}
@media screen and (max-width: 600px) {
  .truck {
    grid-template:
      'filling'
      'empty';
    grid-template-columns: 1fr;
    &.sync {
      grid-template:
        'filling'
        'empty'
        'sync';
      grid-template-columns: 1fr;
    }
    .empty {
      height: auto;
    }
  }
}
</style>
