import { MediumUnity, type Medium } from '@/models/Medium'
import { EmptyableMediaType } from '@/forms/EmptyTruck'

export const DEFAULT_TRUCK_CAPACITY = 5500 // kg

interface TruckArgs {
  parkCode: string
  wasteWeight: number
  hardDriveNumber: number
  hardDriveWeight: number
  dibNumber: number
  dibWeight: number
}

export class Truck {
  constructor(args: TruckArgs) {
    return Object.assign(this, args)
  }

  get totalWeight() {
    return (this.wasteWeight || 0) + (this.hardDriveWeight || 0) + (this.dibWeight || 0)
  }

  get fillingPercentage() {
    return Math.round(((this.wasteWeight || 0) / DEFAULT_TRUCK_CAPACITY) * 100)
  }

  addWeight(media: Medium[]) {
    media.forEach((medium) => {
      const unity = medium.destroyedQuantity.unity
      const quantity = medium.destroyedQuantity.quantity as number

      if (['Papier', 'Papier UC'].includes(medium.label)) {
        this.wasteWeight += quantity
      } else if (['Disque dur', 'Disques durs SSD'].includes(medium.label)) {
        if (unity === MediumUnity.Unity) {
          this.hardDriveNumber += quantity
        } else if (unity === MediumUnity.Kg) {
          this.hardDriveWeight += quantity
        }
      } else {
        if (unity === MediumUnity.Unity) {
          this.dibNumber += quantity
        } else if (unity === MediumUnity.Kg) {
          this.dibWeight += quantity
        }
      }
    })
  }

  resetWeight(mediaType: EmptyableMediaType) {
    if (mediaType === EmptyableMediaType.Paper) {
      this.wasteWeight = 0
    } else if (mediaType === EmptyableMediaType.HardDrive) {
      this.hardDriveNumber = 0
      this.hardDriveWeight = 0
    } else {
      this.dibNumber = 0
      this.dibWeight = 0
    }
  }

  static formatWeight(weight: number = 0) {
    return parseFloat((weight / 1000).toFixed(2))
      .toString()
      .replace('.', ',')
  }
}

export interface Truck extends TruckArgs {}
