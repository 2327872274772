import './assets/css/main.scss'

import { createApp } from 'vue'

import App from './App.vue'
import router from './plugins/router'
import pinia from './plugins/pinia'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import initSentry from './plugins/sentry'

const app = createApp(App)

app.use(router)
app.use(pinia)
app.use(i18n)
app.use(vuetify)

initSentry(app, router)

app.mount('#app')
