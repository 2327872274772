<script setup lang="ts">
import { ref, inject, onMounted, onBeforeUnmount } from 'vue'
import type { Mission } from '@/models/Mission'
import * as geolocation from '@/services/geolocation'

const mission: Mission = inject('mission') as Mission

let map = null
let currentPositionMarker = null
let missionMarker = null
const mapContainer = ref(null)

const openGoogleMaps = () => {
  window.open('http://maps.google.com/?q=' + encodeURIComponent(mission.fullAddress), '_blank')
}

const addCurrentPositionMarker = (position) => {
  const marker = new google.maps.marker.AdvancedMarkerElement({
    map,
    position,
    title: 'Position actuelle'
  })

  const infoWindow = new google.maps.InfoWindow({
    content: 'Position actuelle'
  })
  marker.addListener('click', () => {
    infoWindow.open(map, marker)
  })

  currentPositionMarker = marker
}

const addMissionMarker = (mission: Mission) => {
  const position = geolocation.shortCoords(mission.address.geolocalization)

  const pinViewBackground = new google.maps.marker.PinElement({
    background: '#0068cb',
    glyphColor: '#1f2c3d',
    borderColor: '#1f2c3d'
  })
  const marker = new google.maps.marker.AdvancedMarkerElement({
    map,
    position,
    title: mission.companyName,
    content: pinViewBackground.element
  })

  const infoWindow = new google.maps.InfoWindow({
    content: mission.companyName
  })
  marker.addListener('click', () => infoWindow.open(map, marker))

  missionMarker = marker
}

const showMap = async () => {
  if (!online.value) return

  await google.maps.importLibrary('maps')
  await google.maps.importLibrary('marker')

  // Map
  map = new google.maps.Map(mapContainer.value, {
    zoom: 12,
    center: { lat: 48.8567, lng: 2.3522 }, // Paris
    mapId: 'DESTRUDATA_APP_MAP',
    disableDefaultUI: true,
    zoomControl: true,
    gestureHandling: 'greedy'
  })

  // Mission markers
  addMissionMarker(mission)
  map.setCenter(missionMarker.position)

  // Current position
  try {
    currentPositionMarker = null
    const pos = await geolocation.getCurrentPosition()
    addCurrentPositionMarker(pos)
    geolocation.watchPosition((pos) => (currentPositionMarker.position = pos))
  } catch (e) {
    console.error(e.message)
  }
}

const online = ref(navigator.onLine)
const onlineHandler = async () => {
  online.value = true
  showMap()
}
const offlineHandler = () => {
  online.value = false
  geolocation.clearWatchPosition()
}
window.addEventListener('online', onlineHandler)
window.addEventListener('offline', offlineHandler)

onMounted(showMap)
onBeforeUnmount(() => {
  geolocation.clearWatchPosition()
  window.removeEventListener('online', onlineHandler)
  window.removeEventListener('offline', offlineHandler)
})
</script>

<template>
  <div class="open-google-maps d-flex justify-end mb-4">
    <v-btn class="" variant="outlined" rounded="xl" @click="openGoogleMaps()">Voir dans Maps</v-btn>
  </div>
  <v-sheet class="dark-border" color="transparent" rounded="lg" border>
    <div class="map rounded-lg" ref="mapContainer" v-show="online"></div>
    <p class="error font-italic text-center pa-4" v-show="!online">
      <v-icon icon="wifi_off"></v-icon> La carte n'est pas accessible hors connexion.
    </p>
  </v-sheet>
</template>

<style scoped lang="scss">
.map {
  width: 100%;
  height: 40vh;
}
</style>
