<script setup lang="ts">
import type { Mission } from '@/models/Mission'
import Contact from '@/components/Contact.vue'

const { mission } = defineProps<{
  mission: Mission
}>()

const firstContact = mission.firstContact
const firstMedium = mission.firstMedium
</script>

<template>
  <v-sheet
    :class="{ mission: true, planned: mission.isPlanned, done: mission.isDone }"
    rounded="lg"
  >
    <div class="info">
      <div class="main">
        <h2>{{ mission.companyName }}</h2>
        <address>{{ mission.fullAddress }}</address>
      </div>
      <div class="details">
        <h3><v-icon icon="event"></v-icon> {{ mission.date }}</h3>
        <h3><v-icon icon="schedule"></v-icon> {{ mission.time }}</h3>
        <h3><v-icon icon="local_shipping"></v-icon> camion nº{{ mission.truck.parkCode }}</h3>
      </div>
      <div class="drivers" v-if="mission.hasOtherDrivers">
        <v-icon icon="error" color="primary"></v-icon> <strong>Autre chauffeur :</strong>
        {{ mission.otherDriversString }}
      </div>
      <div class="contact">
        <Contact :contact="firstContact" />
      </div>
      <div class="forecast">
        <p>
          <v-icon icon="description"></v-icon>
          {{ firstMedium.label }}: {{ firstMedium.forecastQuantity.quantity }}
          {{ firstMedium.forecastQuantity.unity }}
        </p>
        <p class="text-warning" v-if="firstMedium.ultraConfidential">
          <v-icon icon="error" color="warning"></v-icon>inclus de l'ultra confidentiel
        </p>
      </div>
      <div class="destruction mt-3" v-if="!mission.isPlanned">
        <v-fade-transition>
          <p v-if="!mission.isSynchronized">
            <v-icon icon="sync"></v-icon>
            En attente de synchronisation
          </p>
        </v-fade-transition>
        <h3>
          {{ firstMedium.destroyedQuantity.quantity }}
          {{ firstMedium.destroyedQuantity.unity }} détruits
        </h3>
      </div>
    </div>
    <v-btn
      class="start"
      :color="mission.isPlanned ? 'primary' : 'grey'"
      :disabled="mission.isDone"
      @click="mission.isPlanned && $router.push({ name: 'mission', params: { id: mission.id } })"
    >
      <v-icon :icon="mission.isPlanned ? 'navigate_next' : 'done'" color="white"></v-icon>
    </v-btn>
  </v-sheet>
</template>

<style scoped lang="scss">
.mission {
  display: grid;
  grid-template-columns: 88.5% 11.5%;
  .info {
    display: grid;
    grid-template:
      'main details'
      'drivers drivers'
      'contact contact'
      'forecast forecast'
      'destruction destruction';
    grid-template-columns: 55% 45%;
    gap: 0.15rem;
    padding: 1.25rem 1.5rem;
    .v-icon {
      font-size: 1.35rem;
      position: relative;
      top: -0.05rem;
      margin-right: 0.25rem;
    }
    .main {
      grid-area: main;
      margin-bottom: 0.5rem;
      h3 {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .details {
      grid-area: details;
      h3 {
        margin: 0.15rem 0;
      }
    }
    .drivers {
      grid-area: drivers;
    }
    .contact {
      grid-area: contact;
    }
    .forecast {
      grid-area: forecast;
    }

    .destruction {
      grid-area: destruction;
      p {
        font-size: 0.8rem;
        font-style: italic;
      }
      .v-icon {
        font-size: 0.85rem;
        top: 0;
      }
    }
    .forecast,
    .destruction {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p {
        margin-right: 1rem;
      }
    }
  }
  &.done .info {
    > *:not(.destruction) {
      *,
      .v-icon,
      :deep(.v-icon) {
        color: #bbb !important;
      }
    }
  }
  .start {
    height: 100%;
    min-width: auto;
    border-radius: 0 8px 8px 0;
    .v-icon {
      font-size: 2.5rem;
    }
  }
}
@media screen and (max-width: 600px) {
  .mission {
    .info {
      grid-template:
        'main'
        'details'
        'drivers'
        'contact'
        'forecast'
        'destruction';
      grid-template-columns: 100%;
    }
  }
}
</style>
