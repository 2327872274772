<script setup lang="ts">
import { ref, computed, inject, onMounted, type Ref, type ComputedRef } from 'vue'
import type { RemovableRef } from '@vueuse/core'
import type { EmptyTruck } from '@/forms/EmptyTruck'
import { useRecyclingCenterStore } from '@/stores/recycling_centers'
import { RecyclingCenterType, type RecyclingCenter } from '@/models/RecyclingCenter'

const recyclingCenterStore = useRecyclingCenterStore()
const centers: Ref<RecyclingCenter[]> = ref(recyclingCenterStore.recyclingCenters)
const centersItems = computed(() =>
  centers.value.map((center) => ({
    title: `<strong>${center.companyName}${
      center.type === RecyclingCenterType.Incinerator ? ' - incinérateur' : ''
    }</strong> - ${center.address.street} - ${center.address.zipCode} ${center.address.city}`,
    value: center
  }))
)

const formState: RemovableRef<EmptyTruck> = inject('formState') as RemovableRef<EmptyTruck>
const showFullForm: ComputedRef<boolean> = inject('showFullForm') as ComputedRef<boolean>

const updateCenters = async () => {
  centers.value = await recyclingCenterStore.getCentersByProximity()

  if (!centers.value.find((c) => c.id === formState.value.recyclingCenter?.id)) {
    formState.value.recyclingCenter = null
  }
}

onMounted(updateCenters)
</script>

<template>
  <v-sheet class="recycling-center px-6 pt-5 pb-2" rounded="lg" v-if="showFullForm">
    <v-label class="required">Recycleur / Entrepôt</v-label>
    <v-autocomplete
      :items="centersItems"
      v-model="formState.recyclingCenter"
      :value-comparator="(c1, c2) => c1.id === c2.id"
      placeholder="Sélectionner un recycleur / entrepôt"
      color="primary"
      variant="outlined"
      density="compact"
      required
    >
      <template #selection="{ item }">
        <div v-html="item.title" />
      </template>
      <template #item="{ item, props }">
        <v-list-item v-bind="props" :value="item.value" :key="`${item.value.id}`">
          <template #title>
            <div class="v-list-item-title-custom" v-html="item.title"></div>
          </template>
        </v-list-item>
      </template>
    </v-autocomplete>
  </v-sheet>
</template>

<style lang="scss">
.v-list-item-title-custom {
  white-space: pre-wrap;
  strong {
    color: inherit;
  }
}
</style>
