import { ref, computed, type Ref } from 'vue'
import { defineStore } from 'pinia'
import * as API from '@/services/api/sync'
import { Mission } from '@/models/Mission'
import type { EmptyTruck } from '@/forms/EmptyTruck'

export const useSyncStore = defineStore(
  'sync',
  () => {
    const dataToSync: Ref<Array<Mission | EmptyTruck>> = ref([])

    const hasDataToSync = computed(() => dataToSync.value.length > 0)

    const emptyTrucksCount = computed(() => {
      const emtpyTrucks = dataToSync.value.filter((d) => !(d instanceof Mission))
      return emtpyTrucks.length
    })

    const isMissionSynchronized = (mission: Mission) => {
      const missionToSync = dataToSync.value.find(
        (d) => d instanceof Mission && d.id === mission.id
      )
      return !missionToSync
    }

    const syncMission = async (mission: Mission) => {
      const result = await API.syncMission(mission)
      if (!result) {
        dataToSync.value.push(mission)
        return false
      }
      return true
    }

    const syncTruck = async (emptyTruck: EmptyTruck) => {
      const result = await API.syncTruck(emptyTruck)
      if (!result) {
        dataToSync.value.push(emptyTruck)
        return false
      }
      return true
    }

    const syncData = async () => {
      if (!navigator.onLine) return false
      if (!hasDataToSync.value) return false

      let dataSynced = false

      for (let i = 0; i < dataToSync.value.length; i++) {
        try {
          const data = dataToSync.value[0]
          const result =
            data instanceof Mission ? await API.syncMission(data) : await API.syncTruck(data)

          if (result) {
            dataToSync.value.splice(i, 1)
            i--
            dataSynced = true
          }
        } catch (e) {
          console.error(e.message)
        }
      }

      return dataSynced
    }

    return {
      dataToSync,
      hasDataToSync,
      emptyTrucksCount,
      isMissionSynchronized,
      syncMission,
      syncTruck,
      syncData
    }
  },
  {
    persist: {
      afterRestore: (ctx) => {
        ctx.store.dataToSync = ctx.store.dataToSync.map((obj: Mission | EmptyTruck) =>
          'status' in obj ? new Mission(obj) : obj
        )
      }
    }
  }
)
