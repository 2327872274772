import { ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import { useSyncStore } from '@/stores/sync'
import { Truck } from '@/models/Truck'
import type { EmptyableMediaType, EmptyTruck } from '@/forms/EmptyTruck'
import { getLocaleISOString } from '../services/utils'

export const useTruckStore = defineStore(
  'trucks',
  () => {
    const trucks: Ref<Truck[]> = ref([])
    const getData = () => trucks.value
    const setData = (data: Truck[]) => (trucks.value = data)
    const getTruck = (parkCode: string) =>
      trucks.value.find((truck) => truck.parkCode === parkCode) || null

    const saveEmptying = async (emptyTruck: EmptyTruck) => {
      emptyTruck.treatmentDate = getLocaleISOString(new Date())

      const syncStore = useSyncStore()
      await syncStore.syncTruck(emptyTruck)

      const truck = getTruck(emptyTruck.truckParkCode as string)
      truck?.resetWeight(emptyTruck.mediaType as EmptyableMediaType)
    }

    return {
      trucks,
      getData,
      setData,
      getTruck,
      saveEmptying
    }
  },
  {
    persist: {
      afterRestore: (ctx) => {
        ctx.store.trucks = ctx.store.trucks.map((truck: Truck) => new Truck(truck))
      }
    }
  }
)
