<script setup lang="ts">
import { inject } from 'vue'
import type { Mission } from '@/models/Mission'

const mission: Mission = inject('mission') as Mission
</script>

<template>
  <v-sheet class="planning px-3 py-1" rounded="lg">
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <h3>Date de début planifiée</h3>
          <p>{{ mission.startDateString }}</p>
        </v-col>
        <v-col cols="12" sm="6">
          <h3>Heure de début planifiée</h3>
          <p>{{ mission.startTime }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pt-0" v-if="mission.endDate">
          <h3>Date de fin planifiée</h3>
          <p>{{ mission.endDateString }}</p>
        </v-col>
        <v-col cols="12" sm="6" class="pt-0" v-if="mission.endTime">
          <h3>Heure de fin planifiée</h3>
          <p>{{ mission.endTime }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pt-0">
          <h3>Camion</h3>
          <p>nº {{ mission.truck?.parkCode }}</p>
        </v-col>
        <v-col cols="12" sm="6" class="pt-0" v-if="mission.hasOtherDrivers">
          <h3><v-icon icon="error" color="primary"></v-icon> Autre chauffeur</h3>
          <p>{{ mission.otherDriversString }}</p>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<style scoped lang="scss">
.planning {
  p {
    font-size: 0.95rem;
  }
  .v-icon {
    font-size: 1.35rem;
    position: relative;
    top: -0.05rem;
  }
}
</style>
