import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import Planning from '@/views/Planning/Planning.vue'
import Mission from '@/views/Mission/Mission.vue'
import Destruction from '@/views/Destruction/Destruction.vue'
import EmptyTruck from '@/views/EmptyTruck/EmptyTruck.vue'
import Login from '@/views/Login.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ top: 0 })
      }, 250)
    })
  },
  routes: [
    {
      path: '/',
      name: 'planning',
      component: Planning,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/mission/:id(\\d+)',
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          name: 'mission',
          component: Mission,
          props: true
        },
        {
          path: 'destruction',
          name: 'destruction',
          component: Destruction,
          props: true
        }
      ]
    },
    {
      path: '/empty-truck',
      name: 'empty-truck',
      component: EmptyTruck,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    }
  ]
})

// Redirect to login if needed
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const authStore = useAuthStore()
    if (authStore.isAuthenticated) {
      next()
    } else {
      next({ name: 'login' })
    }
  } else {
    next()
  }
})

// Keep hash and query params
router.beforeEach((to, from, next) => {
  const isEmpty = (o: object) => Object.keys(o).length === 0

  if ((!isEmpty(from.query) && isEmpty(to.query)) || (from.hash && !to.hash)) {
    next({ ...to, query: from.query, hash: from.hash })
  } else {
    next()
  }
})

export default router
