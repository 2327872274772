import type { Address, Geolocalization } from './Address'
import type { Contact } from './Contact'
import type { Driver } from './Driver'
import type { Medium } from './Medium'
import { useTruckStore } from '@/stores/trucks'
import { getCurrentPosition } from '@/services/geolocation'
import { useSyncStore } from '@/stores/sync'

export enum Period {
  Today = 'today',
  Tomorrow = 'tomorrow',
  TwoWeeks = 'twoWeeks'
}

export interface Document {
  readonly url: string
  readonly name: string
}

export enum MissionStatus {
  Planned = 'planned',
  Done = 'done'
}

interface MissionArgs {
  id: number
  companyName: string
  plannedStartDateTime: string
  plannedEndDateTime: string
  comment?: string
  openingHours?: string
  accessTerms?: string
  address: Address
  contacts: Contact[]
  truckParkCode: string
  documents?: Document[]
  otherDrivers?: Driver[]
  media: Medium[]
  witnessContact?: Contact
  witnessContactName?: string
  wasteTrackNumber?: string
  geolocalization?: Geolocalization
  signature?: string
  signatureDateTime?: string
  newMissionDate?: string
  status?: MissionStatus
}

export class Mission {
  constructor(args: MissionArgs) {
    return Object.assign(this, args)
  }

  get fullAddress() {
    return `${this.address.street} - ${this.address.zipCode} ${this.address.city}`
  }

  get truck() {
    const truckStore = useTruckStore()
    return truckStore.getTruck(this.truckParkCode)
  }

  get firstContact() {
    return this.contacts[0]
  }

  get firstMedium() {
    return this.orderedMedia[0]
  }

  get orderedMedia() {
    const orderedMedia = [...this.media]
    orderedMedia.sort((m1: Medium, m2: Medium) => m1.order - m2.order)
    return orderedMedia
  }

  get forecastedMedia() {
    return this.media.filter((medium) => !!medium.forecastQuantity)
  }

  get startDate() {
    return new Date(this.plannedStartDateTime.replace(' ', 'T'))
  }

  get endDate() {
    return new Date(this.plannedEndDateTime.replace(' ', 'T'))
  }

  get date() {
    if (this.startDate.toDateString() !== this.endDate.toDateString()) {
      const month = this.endDate.toLocaleDateString('fr-FR', { month: 'long' })
      return `du ${this.startDate.getDate()} au ${this.endDate.getDate()} ${month}`
    }

    return this.startDateString
  }

  get time() {
    return this.startTime
  }

  get startDateString() {
    return this.startDate.toLocaleDateString('fr-FR', {
      weekday: 'long',
      day: 'numeric',
      month: 'long'
    })
  }

  get shortStartDate() {
    return this.startDate.toLocaleDateString('fr-FR')
  }

  get startTime() {
    return this.startDate.toLocaleTimeString('fr-FR', { timeStyle: 'short' }).replace(':', 'h')
  }

  get endDateString() {
    return this.endDate.toLocaleDateString('fr-FR', {
      weekday: 'long',
      day: 'numeric',
      month: 'long'
    })
  }

  get endTime() {
    return this.endDate.toLocaleTimeString('fr-FR', { timeStyle: 'short' }).replace(':', 'h')
  }

  get isPlanned() {
    return this.status === 'planned'
  }

  get isDone() {
    return this.status !== 'planned'
  }

  get isPlannedToday() {
    const today = new Date()
    return this.isPlanned && this.startDate.toDateString() === today.toDateString()
  }

  get commentHtml() {
    return this.comment?.replace('\n', '<br />')
  }

  get accessTermsHtml() {
    return this.accessTerms?.replace('\n', '<br />')
  }

  get openingHoursHtml() {
    return this.openingHours?.replace('\n', '<br />')
  }

  get hasOtherDrivers() {
    return this.otherDrivers && this.otherDrivers.length > 0
  }

  get otherDriversString() {
    if (!this.otherDrivers) return ''

    return this.otherDrivers.map((driver) => `${driver.firstName} ${driver.lastName}`).join(', ')
  }

  get isSynchronized() {
    const syncStore = useSyncStore()
    return syncStore.isMissionSynchronized(this)
  }

  inPeriod(period: Period) {
    const today = new Date()

    if (period === Period.Today) {
      return this.startDate.toDateString() === today.toDateString()
    } else if (period === Period.Tomorrow) {
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      return this.startDate.toDateString() === tomorrow.toDateString()
    } else if (period === Period.TwoWeeks) {
      today.setHours(0, 0, 0)
      const twoWeeksLater = new Date()
      twoWeeksLater.setDate(twoWeeksLater.getDate() + 14)
      twoWeeksLater.setHours(23, 59, 59)
      return this.startDate >= today && this.startDate <= twoWeeksLater
    }
    return false
  }

  updateMediaOrder() {
    this.media.forEach((medium: Medium, index: number) => {
      if (!medium.forecastQuantity) {
        medium.order = index + 1
      }
    })
  }

  async setCurrentGeolocalization() {
    try {
      this.geolocalization = await getCurrentPosition(false)
    } catch (e) {
      if (
        e instanceof GeolocationPositionError &&
        e.code === GeolocationPositionError.PERMISSION_DENIED
      ) {
        throw e
      } else {
        this.geolocalization = this.address.geolocalization
      }
    }
  }
}

export interface Mission extends MissionArgs {}
