import i18n from './i18n'
import * as validators from '@vuelidate/validators'

const { createI18nMessage } = validators
const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) })

export const required = withI18nMessage(validators.required)
export const requiredIf = withI18nMessage(validators.requiredIf, { withArguments: true })
export const minLength = withI18nMessage(validators.minLength, { withArguments: true })
export const minValue = withI18nMessage(validators.minValue, { withArguments: true })
export const time = withI18nMessage(validators.helpers.regex(/^\d{1,2}:\d{1,2}$/))
