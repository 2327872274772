<script setup lang="ts">
import { inject } from 'vue'
import type { Mission } from '@/models/Mission'

const mission: Mission = inject('mission') as Mission
</script>

<template>
  <div class="destruction pt-4 pb-8">
    <v-btn
      size="large"
      rounded="xl"
      :disabled="!mission.isPlannedToday"
      @click="$router.push({ name: 'destruction', params: { id: mission.id } })"
    >
      Commencer la destruction
    </v-btn>
    <p class="mt-2" v-if="!mission.isPlannedToday">
      La destruction est prévue pour le : {{ mission.shortStartDate }}
    </p>
  </div>
</template>

<style scoped lang="scss">
.destruction {
  text-align: center;
  p {
    font-style: italic;
  }
}
</style>
