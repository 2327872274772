<script setup lang="ts">
import { Period } from '@/models/Mission'

const props = defineProps<{
  period: Period
}>()
</script>

<template>
  <header class="rounded-lg pa-4">
    <v-btn
      v-for="p in Period"
      class="px-5"
      rounded="md"
      :ripple="false"
      :color="props.period === p ? '#dedc00' : 'white'"
      :variant="props.period === p ? 'flat' : 'text'"
      :key="p"
      @click="$emit('periodChanged', p)"
    >
      {{ $t(`views.home.${p}`) }}
    </v-btn>
    <img src="@/assets/images/logo.svg" alt="Logo DestruData" />
  </header>
</template>

<style scoped lang="scss">
header {
  display: grid;
  grid-template-columns: repeat(4, min-content);
  justify-content: space-between;
  gap: 0.5em;
  background-color: var(--header-color);

  img {
    height: 38px;
  }
}
@media screen and (max-width: 600px) {
  header {
    grid-template-columns: repeat(2, min-content);
  }
}
</style>
