<script setup lang="ts">
import { computed, inject } from 'vue'
import type { Mission } from '@/models/Mission'
import { saveAs } from 'file-saver'

const mission: Mission = inject('mission') as Mission
const hasAccessData = computed(
  () => mission.accessTerms || mission.openingHours || mission.documents?.length
)

/*const downloadFile = async (file: string, name: string) => {
  const blob = await fetch(file).then(r => r.blob())
  const url = URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = name
  document.body.appendChild(a)
  a.click()

  window.URL.revokeObjectURL(url)
  a.remove()
}*/
</script>

<template>
  <v-sheet class="access px-7 py-5" rounded="lg" v-if="hasAccessData">
    <div class="access-terms">
      <h3>Modalités d'accès</h3>
      <p v-if="mission.accessTerms" v-html="mission.accessTermsHtml"></p>
      <p class="empty" v-else>Aucune information renseignée</p>
    </div>
    <div class="opening-hours mt-3">
      <h3>Horaires d'ouverture</h3>
      <p v-if="mission.openingHours" v-html="mission.openingHoursHtml"></p>
      <p class="empty" v-else>Aucune information renseignée</p>
    </div>
    <div class="documents mt-3">
      <h3>Documents</h3>
      <ul v-if="mission.documents && mission.documents?.length > 0">
        <li class="my-2" v-for="doc in mission.documents" :key="doc.url">
          <a href="javascript:void(0)" @click="saveAs(doc.url, doc.name)">
            {{ doc.name }} <v-icon icon="download" color="primary"></v-icon>
          </a>
        </li>
      </ul>
      <p class="empty" v-else>Aucun document</p>
    </div>
  </v-sheet>
</template>

<style scoped lang="scss">
.access {
  p,
  a {
    font-size: 0.95rem;
  }
  .empty {
    font-style: italic;
  }
  .documents {
    a {
      font-weight: 700;
      color: var(--primary-color);
    }
    .v-icon {
      font-size: 1.35rem;
    }
  }
}
</style>
