<script setup lang="ts">
import { ref, inject, type ComputedRef } from 'vue'
import type { RemovableRef } from '@vueuse/core'
import type { EmptyTruck } from '@/forms/EmptyTruck'

const formState: RemovableRef<EmptyTruck> = inject('formState') as RemovableRef<EmptyTruck>
const showFullForm: ComputedRef<boolean> = inject('showFullForm') as ComputedRef<boolean>
const needsQuantityAndDocument: ComputedRef<boolean> = inject(
  'needsQuantityAndDocument'
) as ComputedRef<boolean>

const photoInput = ref(null)
const takePhoto = () => {
  photoInput.value.click()
}
const photoTaken = (event) => {
  const selectedFile = event.target.files[0]
  if (!selectedFile) return

  const reader = new FileReader()
  reader.onload = (e) => {
    const dataURL = e.target.result
    const img = new Image()
    img.src = dataURL

    img.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      const isLandscape = img.width > img.height
      const baseResolution = 1080

      const newWidth = isLandscape ? img.width * (baseResolution / img.height) : baseResolution
      const newHeight = isLandscape ? baseResolution : img.height * (baseResolution / img.width)

      canvas.width = newWidth
      canvas.height = newHeight

      ctx.drawImage(img, 0, 0, newWidth, newHeight)

      const resizedDataURL = canvas.toDataURL('image/jpeg', 0.9)
      console.log('Data URL: ', resizedDataURL)

      formState.value.recyclingDocument = resizedDataURL
    }
  }

  reader.readAsDataURL(selectedFile)
}
</script>

<template>
  <v-sheet
    class="recycling-document pa-6"
    rounded="lg"
    v-if="showFullForm && needsQuantityAndDocument"
  >
    <v-label class="required">Bon de recyclage</v-label>
    <div class="picture add pa-5" v-if="!formState.recyclingDocument">
      <v-btn variant="text" :ripple="false" rounded="xl" @click="takePhoto">
        <v-icon icon="photo_camera" color="primary" size="large"></v-icon> Prendre une photo
      </v-btn>
    </div>
    <div class="picture edit pa-5" v-else>
      <img :src="formState.recyclingDocument" alt="Photo" />
      <v-btn class="mt-5" variant="outlined" rounded="xl" @click="takePhoto">
        <v-icon icon="photo_camera" color="primary" size="large"></v-icon> Modifier la photo
      </v-btn>
    </div>
    <input
      ref="photoInput"
      type="file"
      accept="image/jpeg"
      capture="environment"
      @change="photoTaken"
      v-show="false"
    />
  </v-sheet>
</template>

<style scoped lang="scss">
.recycling-document {
  .picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    .v-btn {
      font-weight: 700;
      .v-icon {
        margin-right: 0.5rem;
      }
    }
    &.add {
      border: 2px dashed rgb(var(--v-theme-primary));
    }
    &.edit {
      img {
        max-width: 50%;
        max-height: 35vh;
      }
    }
  }
}
</style>
