<script setup lang="ts">
import { ref, computed, watch, provide, onMounted, onBeforeUnmount, type Ref } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import { Period, type Mission } from '@/models/Mission'
import { loadDataFromApi } from '@/services/api/load'
import { useAuthStore } from '@/stores/auth'
import { useMissionStore } from '@/stores/missions'
import { useSyncStore } from '@/stores/sync'
import Header from './components/Header.vue'
import Map from './components/Map.vue'
import Truck from './components/Truck.vue'
import Missions from './components/Missions.vue'

const versionNumber = import.meta.env.VITE_VERSION_NUMBER

const authStore = useAuthStore()
const missionStore = useMissionStore()
const syncStore = useSyncStore()

const loading = ref(true)
const showSyncSnackbar = ref(false)

const period = useLocalStorage<Period>('missions_period', Period.Today)
const missions: Ref<Mission[]> = ref([])
watch(period, () => (missions.value = missionStore.getMissionsByPeriod(period.value)))

const plannedMissions = computed(() => missions.value.filter((m) => m.isPlanned))
const doneMissions = computed(() => missions.value.filter((m) => m.isDone))

provide('plannedMissions', plannedMissions)
provide('doneMissions', doneMissions)

const syncData = async () => {
  try {
    loading.value = true
    showSyncSnackbar.value = await syncStore.syncData()
    await loadDataFromApi()
    missions.value = missionStore.getMissionsByPeriod(period.value)
  } catch (e) {
    console.error(e.message)
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  syncData()
  window.addEventListener('online', syncData)
  window.addEventListener('focus', syncData)
})
onBeforeUnmount(() => {
  window.removeEventListener('online', syncData)
  window.removeEventListener('focus', syncData)
})
</script>

<template>
  <div class="page planning">
    <Header :period="period" @period-changed="(p) => (period = p)" />
    <template v-if="!loading">
      <Truck />
      <Map />
      <Missions />
      <div class="actions mt-16 mb-12">
        <v-btn
          class="sync px-5 ma-3"
          variant="outlined"
          size="large"
          rounded="xl"
          @click="syncData"
        >
          <v-icon icon="sync" color="primary" class="mr-2"></v-icon> Actualiser
        </v-btn>
        <v-btn
          class="logout px-5 ma-3"
          variant="outlined"
          size="large"
          rounded="xl"
          @click="authStore.logout"
        >
          <v-icon icon="logout" color="primary" class="mr-2"></v-icon> Se déconnecter
        </v-btn>
      </div>
      <p class="version">v{{ versionNumber }}</p>
    </template>
    <v-progress-circular
      class="loader"
      :size="40"
      color="primary"
      indeterminate
      v-else
    ></v-progress-circular>
    <v-snackbar v-model="showSyncSnackbar" :vertical="true" color="success" :timeout="4000">
      Les données ont été synchronisées avec le serveur.
      <template #actions>
        <v-btn variant="text" color="white" @click="showSyncSnackbar = false"> Fermer </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped lang="scss">
.planning {
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .actions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .v-icon {
      position: relative;
      top: 0.05rem;
    }
  }
  .version {
    position: absolute;
    bottom: 0.5vh;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.8rem;
  }
}
</style>
