<script setup lang="ts">
import { useRegisterSW } from 'virtual:pwa-register/vue'

const { needRefresh, updateServiceWorker } = useRegisterSW()

const update = () => {
  updateServiceWorker()
  needRefresh.value = false
}
const close = () => {
  needRefresh.value = false
}
</script>

<template>
  <v-snackbar v-model="needRefresh" :vertical="true" :timeout="-1">
    Nouvelle version de l'application disponible
    <template #actions>
      <v-btn variant="text" color="white" @click="update"> Recharger </v-btn>
      <v-btn variant="text" color="white" @click="close"> Fermer </v-btn>
    </template>
  </v-snackbar>
</template>
