<script setup lang="ts">
import { ref, computed, inject, type Ref } from 'vue'
import { Mission } from '@/models/Mission'
import MissionRow from './Mission.vue'

const plannedMissions: Ref<Mission[]> = inject('plannedMissions', ref([]))
const doneMissions: Ref<Mission[]> = inject('doneMissions', ref([]))
const noMission = computed(
  () => plannedMissions.value.length === 0 && doneMissions.value.length === 0
)
</script>

<template>
  <main class="missions">
    <MissionRow :mission="m" v-for="m in plannedMissions" :key="m.id"></MissionRow>
    <MissionRow :mission="m" v-for="m in doneMissions" :key="m.id"></MissionRow>
    <v-sheet class="dark-border" rounded="lg" color="transparent" border v-if="noMission">
      <p class="error font-italic text-center pa-4">Aucune mission planifiée.</p>
    </v-sheet>
  </main>
</template>

<style scoped lang="scss">
.missions > * {
  margin: var(--page-section-spacing) 0;
}
</style>
