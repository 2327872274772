<script setup lang="ts">
import type { RouteParams } from 'vue-router'

const props = defineProps<{
  route: RouteParams
}>()
</script>

<template>
  <header class="rounded-lg pa-4">
    <RouterLink class="" :to="props.route">
      <v-icon icon="arrow_back_ios_new" color="white"></v-icon>
      <slot></slot>
    </RouterLink>
    <img src="@/assets/images/logo.svg" alt="Logo DestruData" />
  </header>
</template>

<style scoped lang="scss">
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--header-color);

  a {
    font-size: 0.95rem;
    font-weight: bold;
    color: white;
    text-decoration: none;
    .v-icon {
      font-size: 0.75rem;
      font-weight: bold;
      margin-right: 0.5rem;
    }
  }

  img {
    height: 38px;
  }
}
</style>
