<script setup lang="ts">
import { provide } from 'vue'
import { useMissionStore } from '@/stores/missions'
import type { Mission } from '@/models/Mission'
import Header from '@/components/Header.vue'
import Info from '@/components/MissionInfo.vue'
import Map from './components/Map.vue'
import Planning from './components/Planning.vue'
import Comment from './components/Comment.vue'
import Access from './components/Access.vue'
import Media from './components/Media.vue'
import Destruction from './components/Destruction.vue'

const props = defineProps({
  id: {
    type: [Number, String],
    required: true
  }
})

const id = typeof props.id === 'string' ? parseInt(props.id) : props.id
const missionStore = useMissionStore()
const mission = missionStore.getMission(id) as Mission

provide('mission', mission)
</script>

<template>
  <div class="page mission">
    <Header :route="{ name: 'planning' }">Retour au planning</Header>
    <Info />
    <Map />
    <Planning />
    <Comment />
    <Access />
    <Media />
    <Destruction />
  </div>
</template>
