<script setup lang="ts">
import { ref, provide } from 'vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { useMissionStore } from '@/stores/missions'
import type { Mission } from '@/models/Mission'
import Header from '@/components/Header.vue'
import Info from '@/components/MissionInfo.vue'
import Media from './components/Media.vue'
import Signature from './components/Signature.vue'

const props = defineProps({
  id: {
    type: [Number, String],
    required: true
  }
})

const id = typeof props.id === 'string' ? parseInt(props.id) : props.id
const missionStore = useMissionStore()
const mission = missionStore.getMission(id) as Mission

const router = useRouter()
const newMissionDateRadio = ref('today')
const finalizing = ref(false)
const showCompleteMissionDialog = ref(false)
const showPartialMissionDialog = ref(false)
const showCompleteMissionSnackbar = ref(false)
const showPartialMissionSnackbar = ref(false)
const error = ref('')

const v$ = useVuelidate({}, mission)

const openMissionDialog = async (complete: boolean) => {
  const isFormCorrect = await v$.value.$validate()
  if (!isFormCorrect) return

  if (complete) {
    showCompleteMissionDialog.value = true
  } else {
    showPartialMissionDialog.value = true
  }
}

const finalizeMission = async (complete: boolean) => {
  finalizing.value = true
  try {
    await missionStore.finalizeMission(mission, !complete ? newMissionDateRadio.value : undefined)

    if (complete) {
      showCompleteMissionSnackbar.value = true
    } else {
      showPartialMissionSnackbar.value = true
    }

    setTimeout(() => router.push({ name: 'planning' }), 3000)
  } catch (e) {
    finalizing.value = false
    error.value =
      e instanceof GeolocationPositionError && e.code === GeolocationPositionError.PERMISSION_DENIED
        ? `La finalisation nécessite votre position, veuillez autoriser la géolocalisation.`
        : `La finalisation a échoué, veuillez vérifier les champs du formulaire.`

    console.error(e.message)
    setTimeout(() => (error.value = ''), 5000)
  }
}

provide('mission', mission)
provide('finalizing', finalizing)
</script>

<template>
  <div class="page destruction">
    <Header :route="{ name: 'mission', params: { id } }">Retour à la mission</Header>
    <Info />
    <Media />
    <Signature />
    <p><span class="text-primary">*</span> : informations obligatoires</p>
    <div class="text-center pt-4 pb-8">
      <v-btn size="large" rounded="xl" @click="openMissionDialog(true)">
        Enregistrer la finalisation
      </v-btn>
      <p class="font-italic mt-8 mb-4">
        Ou, si vous devez vider votre camion en cours de mission :
      </p>
      <v-btn size="large" rounded="xl" variant="outlined" @click="openMissionDialog(false)">
        Mission à continuer
      </v-btn>
      <v-fade-transition>
        <p class="text-error mt-3" v-if="error">
          {{ error }}
        </p>
      </v-fade-transition>
    </div>
    <v-dialog width="600" v-model="showCompleteMissionDialog">
      <v-card class="pt-6 pb-7 px-6" rounded="xl">
        <v-card-title class="d-flex justify-space-between font-weight-light">
          Finaliser la mission
          <v-btn
            icon="close"
            size="x-large"
            variant="plain"
            density="compact"
            @click="showCompleteMissionDialog = false"
          ></v-btn>
        </v-card-title>
        <v-card-text class="pt-2 pl-4 pr-5">
          <p class="mb-4">Cette mission sera clôturée et aucune action ne sera possible ensuite.</p>
          <p>Êtes-vous sûr ?</p>
        </v-card-text>
        <v-card-actions class="mt-2 px-4 flex-wrap">
          <v-btn
            size="large"
            rounded="xl"
            variant="outlined"
            class="px-5 my-3"
            @click="showCompleteMissionDialog = false"
          >
            Annuler
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            size="large"
            rounded="xl"
            variant="flat"
            class="px-5 my-3"
            :loading="finalizing"
            :disabled="finalizing"
            @click="finalizeMission(true)"
          >
            Confirmer la finalisation
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="600" v-model="showPartialMissionDialog">
      <v-card class="pt-6 pb-7 px-6" rounded="xl">
        <v-card-title class="d-flex justify-space-between font-weight-light">
          Mission à continuer ?
          <v-btn
            icon="close"
            size="x-large"
            variant="plain"
            density="compact"
            @click="showPartialMissionDialog = false"
          ></v-btn>
        </v-card-title>
        <v-card-text class="pt-2 pl-4 pr-5">
          <p class="mb-4">
            Les quantités renseignées seront enregistrées, une
            <strong>nouvelle mission va être créée</strong> avec les quantités estimées restantes.
          </p>
          <p class="mb-2">Quand souhaitez-vous continuer la mission ?</p>
          <v-radio-group v-model="newMissionDateRadio" inline>
            <v-radio label="Aujourd'hui" color="primary" value="today" class="mr-8"></v-radio>
            <v-radio label="Demain" color="primary" value="tomorrow"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions class="mt-2 px-4 flex-wrap">
          <v-btn
            size="large"
            rounded="xl"
            variant="outlined"
            class="px-5 my-3"
            @click="showPartialMissionDialog = false"
          >
            Annuler
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            size="large"
            rounded="xl"
            variant="flat"
            class="px-5 my-3"
            :loading="finalizing"
            :disabled="finalizing"
            @click="finalizeMission(false)"
          >
            Continuer et créer une mission
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="showCompleteMissionSnackbar"
      :vertical="true"
      color="success"
      :timeout="2500"
    >
      La mission a été finalisée.
      <template #actions>
        <v-btn variant="text" color="white" @click="showCompleteMissionSnackbar = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="showPartialMissionSnackbar"
      :vertical="true"
      color="success"
      :timeout="2500"
    >
      Les informations sont enregistrées, et une nouvelle mission a été créée pour
      {{ newMissionDateRadio === 'tomorrow' ? 'demain' : `aujourd'hui` }}.
      <template #actions>
        <v-btn variant="text" color="white" @click="showPartialMissionSnackbar = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style lang="scss">
.v-dialog {
  .v-card {
    i {
      color: var(--v-theme-primary);
    }
    p {
      font-size: 0.95rem;
    }
  }
}
</style>
