import i18n from '@/plugins/i18n'
import { useAuthStore } from '@/stores/auth'

export const authenticate = (login: string, password: string) => {
  const authStore = useAuthStore()

  return new Promise((resolve, reject) => {
    if (import.meta.env.VITE_USE_MOCKS === 'true') {
      const authenticated = login === '8814' && password === '#DestruData23!'
      authStore.setAuthToken('FAKE_AUTH_TOKEN')
      authenticated ? resolve(true) : reject(new Error(i18n.global.t('errors.loginFailed')))
    } else {
      const data = {
        login: login,
        password: password
      }
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }

      fetch(`${import.meta.env.VITE_API_URL}/authentication`, options)
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error(`Échec de l'authentification`)
          }
        })
        .then((data) => {
          if (data.success) {
            authStore.setAuthToken(data.data.token)
            resolve(true)
          } else {
            throw new Error(`Échec de l'authentification`)
          }
        })
        .catch((error) => {
          authStore.setAuthToken(null)
          reject(error)
        })
    }
  })
}
