import { useAuthStore } from '@/stores/auth'
import { useSyncStore } from '@/stores/sync'
import { useMediaTypeStore } from '@/stores/media_types'
import { useRecyclingCenterStore } from '@/stores/recycling_centers'
import { useTruckStore } from '@/stores/trucks'
import { useMissionStore } from '@/stores/missions'
import { DataType } from './data'
import { format } from './format'

const load = async (dataType: DataType) => {
  const authStore = useAuthStore()
  const apiUrl =
    import.meta.env.VITE_USE_MOCKS !== 'true'
      ? `${import.meta.env.VITE_API_URL}/${dataType}`
      : new URL(`/src/mocks/${dataType}.json`, import.meta.url).href

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authStore.authToken
    }
  }

  const response = await fetch(apiUrl, options)
  if (response.status === 401) {
    authStore.logout()
    throw new Error(
      `[HTTP ${
        response.status
      }] Failed to fetch ${dataType} data, auth token is not valid: ${JSON.stringify(response)}`
    )
  } else if (!response.ok) {
    throw new Error(
      `[HTTP ${response.status}] Failed to fetch ${dataType} data: ${JSON.stringify(response)}`
    )
  }

  const data = await response.json()
  if (!data.success)
    throw new Error(
      `[HTTP ${
        response.status
      }] Failed to fetch ${dataType} data, success is set to false: ${JSON.stringify(data)}`
    )

  return data.data
}

export const loadDataFromApi = async () => {
  const authStore = useAuthStore()
  const syncStore = useSyncStore()
  if (!navigator.onLine || !authStore.isAuthenticated || syncStore.hasDataToSync) return false

  const dataStores = {
    [DataType.MediaType]: useMediaTypeStore(),
    [DataType.RecyclingCenter]: useRecyclingCenterStore(),
    [DataType.Truck]: useTruckStore(),
    [DataType.Mission]: useMissionStore()
  }

  for (const dataType of Object.values(DataType)) {
    const newData = await load(dataType)
    const objects = format(dataType, newData)

    const store = dataStores[dataType]
    store.setData(objects)
  }

  return true
}
