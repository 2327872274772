<script setup lang="ts">
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { authenticate } from '@/services/api/auth'
import { required, minLength } from '@/plugins/vuelidate'

const router = useRouter()

const visiblePassword = ref(false)
const authenticating = ref(false)
const error = ref('')

const formState = reactive({
  login: '',
  password: ''
})

const rules = {
  login: { required },
  password: { required, minLength: minLength(8) }
}

const v$ = useVuelidate(rules, formState)

const login = async () => {
  const isFormCorrect = await v$.value.$validate()
  if (!isFormCorrect) return

  try {
    authenticating.value = true
    await authenticate(formState.login, formState.password)

    router.push('/')
  } catch (e) {
    authenticating.value = false
    error.value = e.message

    setTimeout(() => (error.value = ''), 4000)
  }
}
</script>

<template>
  <v-container class="login">
    <v-row justify="center" class="mt-16">
      <v-col cols="12" sm="8" md="6">
        <v-card class="pa-10" elevation="8" rounded="lg">
          <v-form @submit.prevent="">
            <v-label class="label">
              {{ $t('views.login.driverCode') }}
            </v-label>
            <v-text-field
              v-model="formState.login"
              :error-messages="v$.login.$errors.map((e) => e.$message)"
              placeholder="ex: 8810"
              required
            ></v-text-field>

            <v-label class="label">
              {{ $t('views.login.password') }}
            </v-label>
            <v-text-field
              v-model="formState.password"
              :type="visiblePassword ? 'text' : 'password'"
              :error-messages="v$.password.$errors.map((e) => e.$message)"
              placeholder="8 caractères minimum"
              required
            >
              <template v-slot:append-inner>
                <v-icon
                  :icon="visiblePassword ? 'visibility_off' : 'visibility'"
                  @click="visiblePassword = !visiblePassword"
                  color="primary"
                />
              </template>
            </v-text-field>

            <v-btn
              type="submit"
              class="my-2"
              rounded="xl"
              block
              :loading="authenticating"
              @click="login"
            >
              {{ $t('views.login.login') }}
            </v-btn>

            <v-fade-transition>
              <v-card-text class="text-error pa-0" v-if="error">
                {{ error }}
              </v-card-text>
            </v-fade-transition>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="11" sm="9" md="7">
        <img src="@/assets/images/truck.svg" alt="Camion" />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">
.login {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .v-row {
    flex-grow: 0;
    .v-form {
      position: relative;
      .v-label {
        font-size: 0.9rem;
      }
      .text-error {
        position: absolute;
        line-height: 1.2;
      }
    }
    img {
      width: 100%;
    }
  }
}
</style>
