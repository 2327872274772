import { ref, type Ref } from 'vue'
import { defineStore } from 'pinia'

export const useMediaTypeStore = defineStore(
  'media_types',
  () => {
    const mediaTypes: Ref<string[]> = ref([])
    const getData = () => mediaTypes.value
    const setData = (data: string[]) => (mediaTypes.value = data)

    return {
      mediaTypes,
      getData,
      setData
    }
  },
  {
    persist: true
  }
)
