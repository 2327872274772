<script setup lang="ts">
import { ref, computed, watch, inject, onBeforeUnmount, type ComputedRef } from 'vue'
import type { RemovableRef } from '@vueuse/core'
import { useTruckStore } from '@/stores/trucks'
import { EmptyableMediaType, type EmptyTruck } from '@/forms/EmptyTruck'

const formState: RemovableRef<EmptyTruck> = inject('formState') as RemovableRef<EmptyTruck>
const showFullForm: ComputedRef<boolean> = inject('showFullForm') as ComputedRef<boolean>
const needsQuantityAndDocument: ComputedRef<boolean> = inject(
  'needsQuantityAndDocument'
) as ComputedRef<boolean>

const truckStore = useTruckStore()
const truck = computed(() =>
  formState.value.truckParkCode ? truckStore.getTruck(formState.value.truckParkCode) : null
)

const quantityInTruck = computed(() => {
  if (formState.value.mediaType === EmptyableMediaType.HardDrive) {
    return `${truck.value?.hardDriveNumber} unités et ${truck.value?.hardDriveWeight} kg`
  } else if (formState.value.mediaType === EmptyableMediaType.DIB) {
    return `${truck.value?.dibNumber} unités et ${truck.value?.dibWeight} kg`
  }
  return `${truck.value?.wasteWeight} kg`
})

watch(needsQuantityAndDocument, () => {
  if (truck.value && !needsQuantityAndDocument.value) {
    formState.value.quantity =
      formState.value.mediaType === EmptyableMediaType.HardDrive
        ? truck.value.hardDriveWeight
        : truck.value.dibWeight
  } else {
    formState.value.quantity = null
  }
})

const online = ref(navigator.onLine)
const onlineHandler = () => (online.value = true)
const offlineHandler = () => (online.value = false)

window.addEventListener('online', onlineHandler)
window.addEventListener('offline', offlineHandler)

onBeforeUnmount(() => {
  window.removeEventListener('online', onlineHandler)
  window.removeEventListener('offline', offlineHandler)
})
</script>

<template>
  <v-sheet class="quantity px-3 pt-2" rounded="lg" v-if="showFullForm">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          :sm="needsQuantityAndDocument ? 6 : 12"
          :class="{
            'd-flex': !needsQuantityAndDocument,
            'pb-1': needsQuantityAndDocument,
            'pb-5': !needsQuantityAndDocument
          }"
        >
          <h3 class="mr-2">Quantité dans le camion :</h3>
          <p
            :class="{
              'quantity-in-truck': true,
              'font-italic': !online,
              inline: !needsQuantityAndDocument
            }"
          >
            <template v-if="online">{{ quantityInTruck }}</template>
            <template v-else>Indisponible hors connexion</template>
          </p>
        </v-col>
        <v-col cols="12" sm="6" class="pb-1" v-if="needsQuantityAndDocument">
          <v-label class="required">Quantitée vidée</v-label>
          <v-text-field class="quantity-destroyed" type="number" v-model="formState.quantity">
            <template #append-inner>kg</template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<style scoped lang="scss">
.quantity {
  .quantity-in-truck {
    display: flex;
    align-items: center;
    &:not(.inline) {
      min-height: 48px;
    }
  }
  .quantity-destroyed:deep(.v-field__append-inner) {
    font-size: 0.75rem;
  }
}
</style>
